<template>
  <button :disabled="isLoading" class="button button_red">
    {{ isLoading ? "Please wait..." : text }}
  </button>
</template>

<script>
import NProgress from "nprogress";

export default {
  name: "SaveButton",
  props: {
    isLoading: Boolean,
    text: {
      type: String,
      default: "Save Changes",
    },
  },
  watch: {
    isLoading(newValue) {
      if (newValue) {
        NProgress.start();
      } else {
        NProgress.done();
      }
    },
  },
};
</script>
