<template>
  <section class="login_section">
    <div class="wrapper">
      <div class="login_vertical">
        <div class="login_header_logo"><a href="#"></a></div>
        <div class="login_content">
          <a class="close loginbacktomain" :href="baseUrl"></a>
          <div class="login_title">Welcome Back!</div>
          <p class="login_text">Sign in below to access your account</p>
          <form class="login_form" @submit.prevent="handleLogin">
            <div class="login_form_inputs">
              <label class="login_form_input"
                ><input
                  required=""
                  v-model="loginForm.username"
                  name="username"
                  type="email"
                  placeholder="E-MAIL" /></label
              ><label class="login_form_input login_form_input_password"
                ><input
                  required=""
                  v-model="loginForm.password"
                  name="password"
                  :type="isShowPassword ? 'text' : 'password'"
                  placeholder="Password" /><span
                  @click.prevent="showHidePassword()"
                  class="password_show"
                ></span
              ></label>
            </div>
            <div class="login_forgot">
              <a :href="`${baseUrl}/forgot-password`">Forgot Password?</a>
            </div>
            <div class="login_offers">
              <label class="login_checkbox"
                ><input type="checkbox" v-model="loginForm.rememberMe" /><span
                  >Remember Me</span
                ></label
              >
            </div>

            <div class="login_form_button">
              <SaveButton text="Login" :isLoading="isLoading"></SaveButton>
            </div>
          </form>
          <div class="login_join">
            Not a member? <a :href="`${baseUrl}?signup=true`">Join now</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SaveButton from "@/components/SaveButton";
export default {
  name: "Login",
  components: { SaveButton },
  data() {
    return {
      isShowPassword: false,
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
      },
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
    };
  },
  methods: {
    showHidePassword() {
      this.isShowPassword = !this.isShowPassword;
    },
    handleLogin() {
      this.isLoading = true;
      this.$store
        .dispatch("user/login", this.loginForm)
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (document.getElementsByTagName("header").length) {
      document.getElementsByTagName("header")[0].style.display = "none";
    }
    if (document.getElementsByTagName("nav").length) {
      document.getElementsByTagName("nav")[0].style.display = "none";
    }
  },
};
</script>

<style scoped>
@import "~@/assets/login/css/login.css";

.login_section {
  background: url("~@/assets/img/loginpage_bg.jpg") #161616 no-repeat
    center/cover;
  text-align: center;
  color: #fff;
  font-family: "Trade Gothic", "Open Sans", "sans-serif", "Arial";
}

.login_section input {
  font-family: "Trade Gothic", "Open Sans", "sans-serif", "Arial";
}

.login_vertical {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 240px;
  padding-bottom: 180px;
}

.login_header_logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 52px 0;
}

.login_header_logo a {
  display: inline-block;
  width: 228px;
  height: 81px;
  background: url("~@/assets/img//atu_logo.png") no-repeat center/contain;
}

.login_content {
  max-width: 870px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: url("~@/assets/img/login_content.jpg") #212121 no-repeat left
    top/cover;
  padding: 104px 115px 72px;
  align-self: baseline;
}

.login_title {
  font-size: 60px;
  line-height: 65px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 27px;
}

.login_text {
  max-width: 460px;
  min-height: 48px;
  margin: 0 auto 43px;
}

.login_form {
  width: 100%;
  display: block;
}

.login_form_input {
  display: block;
  width: 100%;
  padding-bottom: 28px;
  padding-top: 6px;
  position: relative;
}

.login_form_input input {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #fff;
  transition: all 0.2s ease-in-out;
  border-bottom: 2px solid #656565;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 82px;
  background: none;
}

.login_form_input input::placeholder {
  text-transform: uppercase;
  color: #b1b1b1;
}

.login_form_input input:focus {
  border-bottom-color: #fff;
}

.login_form_input input:-webkit-autofill,
.login_form_input input:-webkit-autofill:hover,
.login_form_input input:-webkit-autofill:focus {
  background: none;
}

.login_form_input_password {
  position: relative;
}

.login_form_input_password input {
  padding-right: 36px;
}

.login_form_input_password .password_show {
  display: block;
  width: 22px;
  height: 15px;
  background: url("~@/assets/img/passwordshow.svg") no-repeat center/22px 15px;
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 2;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.login_form_input_password .password_show:hover {
  background-image: url("~@/assets/img/passwordshow_h.svg");
}

.login_form_inputs {
  margin-bottom: 20px;
}

.login_forgot {
  text-align: right;
  font-size: 16px;
  line-height: 24px;
  color: #b1b1b1;
  letter-spacing: 0.02em;
  margin-bottom: 35px;
}

.login_forgot a:hover {
  color: #fff;
}

.login_offers {
  margin-bottom: 23px;
}

.login_checkbox {
  display: inline-block;
  font-size: 20px;
  line-height: 24px;
  color: #b1b1b1;
  cursor: pointer;
  letter-spacing: 0.02em;
}

.login_checkbox input {
  display: none !important;
}

.login_checkbox span {
  display: block;
  position: relative;
  padding-left: 58px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.login_checkbox span:before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #646464;
  position: absolute;
  left: 0;
  top: 0;
  background: no-repeat center / 21px auto;
  transition: all 0.2s ease-in-out;
}

.login_checkbox input:checked + span:before {
  background-color: #161616;
  background-image: url("~@/assets/img/check_white.png");
  border-color: transparent;
}

.login_form_button {
  margin-bottom: 39px;
}

.login_form_button .button {
  width: 100%;
}

.login_join {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #b1b1b1;
}

.login_join a {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.login_join a:hover {
  border-bottom-color: transparent;
}

@media (max-width: 1279px) {
  .login_vertical {
    padding-top: 270px;
  }

  .login_content {
    padding: 78px 60px 72px;
  }

  .login_title {
    font-size: 52px;
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .login_vertical {
    padding-top: 0;
    padding-bottom: 30px;
    display: block;
  }

  .login_header_logo {
    padding-top: 38px;
    padding-bottom: 37px;
    position: static;
  }

  .login_header_logo a {
    width: 114px;
    height: 41px;
  }

  .login_content {
    padding: 55px 20px 62px;
  }

  .login_title {
    font-size: 24px;
    line-height: 30px;
  }

  .login_text {
    font-size: 14px;
    line-height: 22px;
  }

  .login_form_input input {
    font-size: 14px;
  }

  .login_text {
    margin-bottom: 22px;
  }

  .login_forgot {
    font-size: 16px;
  }

  .login_form_inputs {
    margin-bottom: 5px;
  }

  .login_offers {
    text-align: left;
    margin-bottom: 14px;
  }

  .login_checkbox {
    font-size: 16px;
  }

  .login_checkbox span {
    padding-left: 40px;
  }

  .login_checkbox span:before {
    width: 24px;
    height: 24px;
    top: 6px;
  }

  .login_form_button {
    margin-bottom: 19px;
  }

  .login_form_button .button {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  .login_form_input {
    padding-top: 0;
  }

  .login_form_input input {
    padding-top: 6px;
  }

  .login_form_input_password .password_show {
    top: 10px;
  }
}

.login_content {
  position: relative;
}

.loginbacktomain {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 3;
}

@media (max-width: 767px) {
  .loginbacktomain {
    top: 20px;
    right: 20px;
  }
}
</style>
